import { RevenueReconciliationReportEntity } from "domain/entity/RevenueReconciliationReport/RevenueReconciliationReportEntity";
import { PrintQueueRepository } from "domain/repository/Common/PrintQueue";
import { ExchangeRateRepository } from "domain/repository/ExchangeRate/ExchangeRateRepo";
import { RevenueReconciliationReportRepository } from "domain/repository/RevenueReconciliationReport/RevenueReconciliationReportRepo";
import _ from "lodash";
import { RevenueReconciliationReportModel } from "presentation/model/RevenueReconciliationReport/RevenueReconciliationReportModel";
import { Dispatch, SetStateAction } from "react";
import { GroupCheckboxList, IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface RevenueReconciliationReportVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<RevenueReconciliationReportModel>> | ((value: SetStateAction<RevenueReconciliationReportModel>) => void),
    ],
    revenueReconciliationReportRepo: RevenueReconciliationReportRepository,
    printQueueRepo: PrintQueueRepository,
    exchangeRateRepo: ExchangeRateRepository,
}

export const RevenueReconciliationReportVM = ({ dispatch, revenueReconciliationReportRepo, printQueueRepo, exchangeRateRepo }: RevenueReconciliationReportVMProps) => {
    const [revenueReconciliationReportDispatch] = dispatch;

    const loadDropdownOption = async () => {

        await exchangeRateRepo.getAllCurrencies().then(
            currencies => {
                let currencyDropdownOptions = currencies?.map((currency) => ({
                    dropdownLabel: currency.currencyCode,
                    tagLabel: currency.currencyCode,
                    value: currency.currencyCode,
                })) ?? []
                currencyDropdownOptions = _.orderBy(currencyDropdownOptions, "dropdownLabel");
                revenueReconciliationReportDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        currencyDropdownOptions: currencyDropdownOptions,
                    },
                    currentReport: {
                        ...prevState.currentReport,
                        currency: currencyDropdownOptions.length > 0 ? currencyDropdownOptions[0].value : '',
                    }
                }))
            }
        )


        await printQueueRepo.getAllPrintQueues().then(
            datas => {
                let printQueues = datas?.filter(entity => entity.activeInd === 'Y');

                const dataObj = printQueues.reduce((acc, item) => {
                    const key = item.id;
                    const value = item.printQueueName;
                    acc[value] = key;
                    return acc;
                }, {} as { [key: string]: number });


                let printQueueDropdownOptions = printQueues?.map((printQueue) => ({
                    dropdownLabel: printQueue.printQueueName,
                    tagLabel: printQueue.printQueueName,
                    value: printQueue.printQueueName,
                })) ?? []
                printQueueDropdownOptions = _.orderBy(printQueueDropdownOptions, "dropdownLabel");

                revenueReconciliationReportDispatch(prevState => ({
                    ...prevState,
                    printQueueIdMap: dataObj,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        printQueueDropdownOptions: printQueueDropdownOptions,
                    },
                    currentReport: {
                        ...prevState.currentReport,
                        printer: printQueueDropdownOptions.length > 0 ? printQueueDropdownOptions[0].value : '',
                    }
                }))
            }
        )
    }

    const initRevenueReconciliationReportList = async () => {
        await revenueReconciliationReportRepo.initRevenueReconciliationReportList().then(data => {
            const [reportIds, reportNames] = data.reduce<[string[], string[]]>((acc, item) => {
                const [key, value] = item.split(':', 2);
                acc[0].push(key);
                acc[1].push(value.trim());
                return acc;
            }, [[], []] as [string[], string[]]);

            const dataObj = data.reduce((acc, item) => {
                const [key, value] = item.split(':', 2);
                acc[value] = key.trim();
                return acc;
            }, {} as { [key: string]: string });

            let reportTypeCheckboxOption = reportNames?.map((reportName) => ({
                key: reportName,
                name: reportName,
                disable: false,
            })) ?? []

            reportTypeCheckboxOption = _.orderBy(reportTypeCheckboxOption, "name");
            revenueReconciliationReportDispatch(prevState => {
                return {
                    ...prevState,
                    reportIdTypeMap: dataObj,
                    reportIdList: reportIds,
                    reportTypeCheckboxOptions: reportTypeCheckboxOption,
                }
            })
        })
    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;

        revenueReconciliationReportDispatch(prevState => {
            return {
                ...prevState,
                currentReport: {
                    ...prevState.currentReport,
                    [fieldKey]: val,
                },
            }
        })
    }


    const onResetClick = async () => {
        revenueReconciliationReportDispatch(prevState => ({
            ...prevState,
            currentReport: {
                ...prevState.currentReport,
                reportTypeList: [],
                currency: prevState.dynamicOptions.currencyDropdownOptions.length > 0 ? prevState.dynamicOptions.currencyDropdownOptions[0].value : '',
                cutOffDateFrom: null,
                cutOffDateTo: null,
                printer: prevState.dynamicOptions.printQueueDropdownOptions.length > 0 ? prevState.dynamicOptions.printQueueDropdownOptions[0].value : '',
            }
        }))
    }

    const onPrintClick = async (reportIdTypeMap: { [key: string]: string }, entity: RevenueReconciliationReportEntity) => {
        let data = { ...entity };
        data = {
            ...data,
            reportTypeList: data.reportTypeList?.map(type =>
                reportIdTypeMap[type] || type
            )
        }
        return await revenueReconciliationReportRepo.print(data);
    }


    const onViewClick = async (reportIdTypeMap: { [key: string]: string }, entity: RevenueReconciliationReportEntity) => {
        let data = { ...entity };
        data = {
            ...data,
            reportTypeList: data.reportTypeList?.map(type =>
                reportIdTypeMap[type] || type
            )
        }
        return await revenueReconciliationReportRepo.view(data).then((data) => {
            if (data && data.data) {
                const res = data.data;
                for (const key in res) {
                    if (res[key]) {
                        const tempData = res[key] as string;
                        let base64 = tempData;
                        let bstr = atob(base64);
                        let n = bstr.length;
                        let u8arr = new Uint8Array(n);
                        while (n--) {
                            u8arr[n] = bstr.charCodeAt(n)
                        }
                        const blob = new Blob([u8arr], { type: 'application/pdf' });
                        const url = URL.createObjectURL(blob);
                        window.open(url, '_blank');
                        URL.revokeObjectURL(url);
                    }
                }
                return true;
            }
            return false;
        })
    }


    const onGroupCheckboxChange = (e: (GroupCheckboxList | undefined)[]) => {
        let selectedValue: string[] = [];
        if (e) {
            e?.forEach(function (value, index) {
                if (value) {
                    selectedValue.push(value.key);
                }
            });
        }
        revenueReconciliationReportDispatch(prevState => ({
            ...prevState,
            currentReport: {
                ...prevState.currentReport,
                reportTypeList: selectedValue,
            }
        }));
    };

    return {
        onGroupCheckboxChange: onGroupCheckboxChange,
        onPrintClick: onPrintClick,
        onViewClick: onViewClick,
        onResetClick: onResetClick,
        onHeaderFieldChange: onHeaderFieldChange,
        loadDropdownOption: loadDropdownOption,
        initRevenueReconciliationReportList: initRevenueReconciliationReportList,
    }
}